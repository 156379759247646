import { createStore } from 'vuex'
import axios from 'axios'
import {
  isLocal,
  isCancelMagicsnapPro,
  isStageMagicsnapPro,
  isCancelUbodyFit,
  isCancelUbodyKz,
  isCancelUstretchSite,
  isCancelUbodySite,
  isCancelUFoodSite
} from '@/utils/stand'

// import the auto exporter
import modules from './modules'

const getConfigUrl = () => {
  switch (true) {
    case isCancelMagicsnapPro():
    case isLocal():
      return 'https://config.artdeepfilters.com/api/v1/config?key=cancel.magicsnap.pro'
    case isStageMagicsnapPro():
      return 'https://config.artdeepfilters.com/api/v1/config?key=stage.magicsnap.pro'
    case isCancelUbodyFit():
      return 'https://config.artdeepfilters.com/api/v1/config?key=cancel.ubody.fit'
    case isCancelUbodyKz():
      return 'https://config.artdeepfilters.com/api/v1/config?key=cancel.ubody.kz'
    case isCancelUstretchSite():
      return 'https://config.artdeepfilters.com/api/v1/config?key=cancel.ustretch.site'
    case isCancelUbodySite():
      return 'https://config.pwhelp.ru/api/v1/config?key=cancel.ubody.site'
    case isCancelUFoodSite():
      return 'https://config.artdeepfilters.com/api/v1/config?key=cancel.u-food.site'
  }
}

export const config = axios.create({
  baseURL: getConfigUrl()
})

export const cdp = axios.create({
  baseURL: 'https://cdp.sigmamobi.com/api/'
  // baseURL: 'http://devel.sigmadev.ru:8103/api/'
})

const store = createStore({
  modules
})

export function useStore() {
  return store
}

export default store
